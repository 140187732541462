export default {
  emailEmpty: '이메일을 입력해주세요.',
  emailInvalid: '유효한 이메일을 입력해주세요.',
  pwEmpty: '비밀번호를 입력해주세요.',
  pwUnmatched: '비밀번호가 일치하지 않습니다.',
  termsRequired: '서비스 이용약관에 동의해주세요.',
  privacyRequired: '개인정보 처리방침에 동의해주세요.',
  userInvalid: '로그인 정보가 일치하지 않습니다.',
  userNotExist: '존재하지 않는 회원입니다.',
  userExist: '이미 존재하는 회원입니다.',
  userBlocked: '계정이 차단되었습니다.',
  userDeleted: '계정이 삭제되었습니다.',
  reLoginPlease: '다시 로그인해주세요.',
  profileImageEmpty: '프로필 이미지를 선택해주세요.',
  nicknameEmpty: '닉네임을 입력해주세요.',
  nicknameTooLong: '닉네임을 10자 이하로 입력해주세요.',
  orientationEmpty: '성향을 선택해주세요.',
  bioEmpty: '자기소개를 입력해주세요.',
  bioTooLong: '자기소개를 140자 이하로 입력해주세요.',
  intervalTooShort: '너무 빠른 시간 안에 다른 회원에게 채팅할 수 없습니다.',
  pointShortage: '포인트가 부족합니다.',
  pwaCannotInstall: '앱이 이미 설치되어 있습니다.',
  serverError: '잠시 후 다시 시도해주세요.',

  //pwa
  pwaTitle: '공식 앱 설치 가이드',
  pwaSubTitle: '앱을 홈 화면에 추가하고<br>언제 어디서나 Deep*에 접속하세요.',
  pwaAndroidBrowser: '<a href="https://www.google.com/chrome/" target="_blank"><u>Chrome</u></a>에서 이 단계를 따르세요.',
  pwaAndroid1: '1. <span class="highlight">더보기</span> 아이콘을 탭하세요.',
  pwaAndroid2: '2. <span class="highlight">홈 화면에 추가</span> 를 탭하세요.',
  pwaAndroid3: '3. 팝업에서 <span class="highlight">설치</span> 를 탭하세요.',
  pwaAndroid4: '4. 팝업에서 <span class="highlight">설치</span> 를 탭하세요.',
  pwaIPhone1: '1. <span class="highlight">공유</span> 아이콘을 탭하세요.',
  pwaIPhone2: '2.<span class="highlight">홈 화면에 추가</span> 를 탭하세요.',
  pwaIPhone3: '3. 오른쪽 상단 <span class="highlight">추가</span> 를 탭하세요.',
  pwaDesktopBrowser: '<a href="https://www.google.com/chrome/" target="_blank"><u>Chrome</u></a> 또는 <a href="https://www.microsoft.com/edge" target="_blank"><u>Edge</u></a>에서 이 단계를 따르세요.',
  pwaDesktop1: '상단 주소창의 설치 아이콘 및 설치 버튼을 탭하거나, 아래의 설치 버튼을 탭하세요. 시크릿 모드의 경우 설치 아이콘이 표시되지 않을 수 있습니다.',
  pwaOtherBrowser: '<a style="color:var(--black-1) !important;"><u>다른 브라우저를 사용하시나요? 더 많은 설치 가이드가 준비되어 있어요.</u></a>',
  pwaInstall: '설치',

  pwaMobileTitle: 'Mobile 설치 가이드',
  pwaSamsungAndroid: '· 삼성 인터넷 on Android',
  pwaSamsungAndroid1: '1. <span class="highlight">더보기</span> 아이콘을 탭하세요.',
  pwaSamsungAndroid2: '2. <span class="highlight">현재 페이지 추가</span>를 탭하세요.',
  pwaSamsungAndroid3: '3. <span class="highlight">홈 화면</span>를 탭하세요.',
  pwaSamsungAndroid4: '4. <span class="highlight">추가</span> 버튼을 탭하세요.',
  pwaFirefoxAndroid: '· Firefox on Android',
  pwaFirefoxAndroid1: '1. <span class="highlight">더보기</span> 아이콘을 탭하세요.',
  pwaFirefoxAndroid2: '2. <span class="highlight">홈 화면에 추가</span>를 탭하세요.',
  pwaFirefoxAndroid3: '3. <span class="highlight">추가</span> 버튼을 탭하세요.',
  pwaEdgeAndroid: '· Edge on Android',
  pwaEdgeAndroid1: '1. <span class="highlight">더보기</span> 아이콘을 탭하세요.',
  pwaEdgeAndroid2: '2. <span class="highlight">휴대폰에 추가</span>를 탭하세요.',
  pwaEdgeAndroid3: '3. <span class="highlight">설치</span> 버튼을 탭하세요.',
  pwaFirefoxIOS: '· Firefox on iOS',
  pwaFirefoxIOS1: '1. <span class="highlight">공유</span> 아이콘을 탭하세요.',
  pwaFirefoxIOS2: '2. <span class="highlight">홈 화면에 추가</span>를 탭하세요.',
  pwaFirefoxIOS3: '3. <span class="highlight">추가</span> 버튼을 탭하세요.',
  pwaEdgeIOS: '· Edge on iOS',
  pwaEdgeIOS1: '1. <span class="highlight">공유</span> 아이콘을 탭하세요.',
  pwaEdgeIOS2: '2. <span class="highlight">홈 화면에 추가</span>를 탭하세요.',
  pwaEdgeIOS3: '3. <span class="highlight">추가</span> 버튼을 탭하세요.',
  pwaDesktopTitle: 'Desktop 설치 가이드',
  pwaEdgeDesktop: '· Edge on Windows and Mac',
  pwaEdgeDesktop1: '1. <span class="highlight">앱</span> - <span class="highlight">이 사이트를 앱으로 설치</span> 를 탭하세요.',
  pwaEdgeDesktop2: '2. <span class="highlight">설치</span> 버튼을 탭하세요.',
  pwaSafariMacOS: '· Safari on Mac',
  pwaSafariMacOS1: '1. 오른쪽 상단 <span class="highlight">공유</span> 아이콘을 탭하세요.',
  pwaSafariMacOS2: '2. <span class="highlight">Dock에 추가</span> 를 탭하세요.',
  pwaSafariMacOS3: '3. <span class="highlight">추가</span> 버튼을 탭하세요.',

  // dialog
  confirmLogout: '정말 로그아웃할까요?',
  confirmAccountDelete: '정말 Deep에서 떠나시겠어요?',
  requestNotification: '채팅 알림을 위해 권한을 허용해주세요.',

  // widgets
  appSubTitle: 'BDSM 데이팅 & 매칭',

  loginTitle: '로그인',
  loginEmail: '이메일',
  loginPassword: '비밀번호',
  loginRegister: '새로운 계정으로 회원가입',

  registerTitle: '회원가입',
  registerNewEmail: '새로운 이메일',
  registerNewPassword: '새로운 비밀번호',
  registerRePassword: '비밀번호 확인',
  registerBack: '로그인으로 돌아기기',
  registerTerms: '서비스 이용약관 동의',
  registerPrivacy: '개인정보처리방침 및 이용약관 동의',

  profileTitle: '프로필',

  profileInitComplete: '프로필 설정 완료',
  profileInitNickname: '닉네임',
  profileInitOrientation: '성향',
  profileInitGenderM: '남성',
  profileInitGenderF: '여성',
  profileInitBio: '회원님에 대해 알려주세요',

  profileEditComplete: '프로필 수정 완료',
  profileEditNickname: '닉네임',
  profileEditOrientation: '성향',
  profileEditGenderM: '남성',
  profileEditGenderF: '여성',
  profileEditBio: '회원님에 대해 알려주세요',

  mainChat: '채팅',

  chatTitle: '채팅',

  settingsTitle: '설정',
  settingsProfileEdit: '프로필 수정',
  settingsAccountLogout: '로그아웃',
  settingsAccountDelete: '회원탈퇴',

  alertOK: '확인',
  alertBack: '뒤로가기',

  gender: {
    male: "남성",
    female: "여성",
  },
  
  orientation: {
    owner: "오너",
    daddy: "대디",
    mommy: "마미",
    dominant: "도미넌트",
    master: "마스터",
    hunter: "헌터",
    brattamer: "브랫테이머",
    degrader: "디그레이더",
    rigger: "리거",
    boss: "보스",
    sadist: "사디스트",
    spanker: "스팽커",
    pet: "펫",
    little: "리틀",
    submissive: "서브미시브",
    slave: "슬레이브",
    prey: "프레이",
    brat: "브랫",
    degradee: "디그레이디",
    ropebunny: "로프버니",
    servant: "서번트",
    masochist: "마조히스트",
    spankee: "스팽키",
    switch: "스위치",
  },

  // else
  default: '',
  
};