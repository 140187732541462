import api from '../../rest/api';

const initialState = {
  ouid: null,
  gender: null,
  nickname: null,
  profileImage: null,
  profileImage_thumbnail: null,
  orientationType: null,
  orientation: null,
  bio: null
};

export default {
  state: { ...initialState },
  mutations: {
    setOUID(state, data) {
      state.ouid = data;
    },
    setOGender(state, data) {
      state.gender = data;
    },
    setONickname(state, data) {
      state.nickname = data;
    },
    setOProfileImage(state, data) {
      state.profileImage = data;
    },
    setOProfileImageThumbnail(state, data) {
      state.profileImage_thumbnail = data;
    },
    setOOrientationType(state, data) {
      state.orientationType = data;
    },
    setOOrientation(state, data) {
      state.orientation = data;
    },
    setOBio(state, data) {
      state.bio = data;
    },
  },
  getters: {
    getOUID(state) {
      return state.ouid
    },
    getOGender(state) {
      return state.gender;
    },
    getONickname(state) {
      return state.nickname;
    },
    getOProfileImage(state) {
      return state.profileImage;
    },
    getOProfileImageThumbnail(state) {
      return state.profileImage_thumbnail;
    },
    getOOrientationType(state) {
      return state.orientationType;
    },
    getOOrientation(state) {
      return state.orientation;
    },
    getOBio(state) {
      return state.bio;
    },
  },
  actions: {
    async userGet({ commit }, { accessToken, ouid }) {
      try {
        const response = await api.userGet(accessToken, ouid);
        const code = response.data.code;
        if(code == "200") {
          const { gender, nickname, profile_image, profile_image_thumbnail, orientation_type, orientation, bio } = response.data.data;
          commit('setOUID', ouid);
          commit('setOGender', gender);
          commit('setONickname', nickname);
          commit('setOProfileImage', profile_image);
          commit('setOProfileImageThumbnail', profile_image_thumbnail);
          commit('setOOrientationType', orientation_type);
          commit('setOOrientation', orientation);
          commit('setOBio', bio);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
  },
};