<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1024px;">

        <v-app-bar class="mb-6 app-bar" style="background-color: var(--black-1);" elevation="0" app>
          <v-toolbar-title>
            <v-img :src="require('@/assets/ic_logo.png')" alt="Logo" width="60" height="60" contain></v-img>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn to="/settings" icon small class="mr-0">
            <v-img :src="profileImage" class="main-profile-img-view rounded-circle" alt="my profile image" width="40" height="40" cover fill-hieght></v-img>
          </v-btn>
        </v-app-bar>

        <v-app-bar class="d-flex align-center justify-center app-tool-bar" app>
          <v-btn v-for="(filter, index) in feed.filters" 
            :key="'filter-' + index" 
            :class="{ 'selected-btn': feed.selectedFilter === filter }" 
            @click="reload(filter)"
            class="main-filter-btn mx-2" text
          > <b>{{ filter }}</b> </v-btn>
        </v-app-bar>

        <v-container v-if="!isPWA">
          <v-btn @click="pwaGuideBtnCkd()" class="white--text font-weight-bold mt-1 mb-1" style="font-size: 14px !important;width: 100%;background-color: var(--black-3);cursor: pointer;">Deep* 앱 설치하기 →</v-btn>
        </v-container>

        <v-container class="py-2">
          <v-row justify-space-between>
            <v-col v-for="(user, index) in feed.users" :key="`user-${user.id}-${index}`" cols="6" sm="6" md="3" class="px-2 mb-4">
              <v-card 
                class="mx-auto clickable-card" 
                dark 
                color="var(--black-2)"
                @click="profileBtnCkd(user.id)">
                
                <v-img
                  height="180"
                  class="main-cell-profile-image-view"
                  :src="getComputedProfileImage(user.profile_image)"
                  alt="profile image"
                  :aspect-ratio="1"
                  cover
                  fill-height/>

                <v-card-text>
                  <div class="white--text font-weight-bold main-cell-nickname-view font-weight-bold">{{ user.nickname }}</div>
                  <div class="grey--text main-cell-profile-extra-view mt-2 mb-1">{{ getComputedOrientation(user.orientation) + ' · ' + getComputedGender(user.gender) }}</div>
                  <!-- <div class="grey--text text-truncate main-cell-bio-view">{{ user.bio }}</div> -->
                </v-card-text>

                <v-card-actions style="margin-top:-8px">
                  <v-btn 
                    block 
                    class="white--text" 
                    style="background-color: var(--red);"
                    @click.stop="chatBtnCkd(user.id)">
                    <b>{{$t('mainChat')}}</b>
                  </v-btn>
                </v-card-actions>

              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-btn fab large fixed bottom right class="main-chat-btn rounded-circle" width="56" height="56" to="/chat-list">
          <v-img :src="chatImage" alt="Chat Icon" width="56" height="56"></v-img>
        </v-btn>

        <img
          v-if="feed.isLoading"
          :src="require('@/assets/ic_spinner.png')"
          width="20"
          height="20"
          alt="Loading..."
          class="mt-5"/>

        <v-snackbar v-model="snackbar.visibility">
          <v-container class="text-center pd-0" style="padding: 0;">
            {{ snackbar.text }}
          </v-container>
          <template v-slot:actions>
            </template>
        </v-snackbar>

        <v-dialog v-model="dialog.dialogVisibility" max-width="400px">
          <v-card style="background-color: var(--black-2)">
            <v-card-title style="color: white; font-size: 16px;">
              {{ dialog.dialogTitle }}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="var(--red)" style="color: white;" @click="confrimDialog">
                {{ $t('alertOK') }}
              </v-btn>
              <v-btn color="var(--black-3)" dark @click="dialog.dialogVisibility = false">
                {{ $t('alertBack') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import { getToken } from "firebase/messaging";
import {CHAT_SERVER} from '../rest/api'
import io from 'socket.io-client';
import { AnalyticsFunctions } from '../rest/analytics/functions.js';
import { AnalyticsParams } from '../rest/analytics/params.js';

const loadCount = 30;

export default {
  name: 'MainView',
  data() {
    return {
      isPWA: false,
      socket: null,
      profileImage: require('@/assets/ic_profile_placeholder.png'),
      chatImage: require('@/assets/ic_chat.png'),
      feed: {
        isLoading: false,
        isDataEnd: false,
        users: [],
        order: `ORDER BY updated_at DESC LIMIT ${loadCount} OFFSET`,
        filters: ['전체', '여성', '남성'],
        selectedFilter: '전체',
        offset: 0,
      },
      dialog: {
        dialogVisibility: false,
        dialogTitle: '',
        dialogAction: '',
      },
      snackbar: {
        text: "",
        visibility: false,
      }
    };
  },
  computed: {
    getComputedFilter() {
      switch (this.feed.selectedFilter) {
        case '여성':
          return " AND gender = 'female'";
        case '남성':
          return " AND gender = 'male'";
        default:
          return '';
      }
    },
    getComputedProfileImage() {
      return (imageUrl) => {
        if (imageUrl && imageUrl !== "null" && imageUrl !== "") {
          return imageUrl;
        }
        return require('@/assets/ic_profile_placeholder.png');
      }
    },
    getComputedOrientation() {
      return (orientation) => {
        const translated = this.$t(`orientation.${orientation}`);
        return typeof translated === 'string' ? translated : orientation;
      }
    },
    getComputedGender() {
      return (gender) => {
        const translated = this.$t(`gender.${gender}`);
        return typeof translated === 'string' ? translated : gender;
      }
    }
  },
  methods: {
    tokenRefresh() {
      this.$store.dispatch('tokenRefresh', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID
      })
      .then((result) => {
        if (!result.success) {
          this.snackbar.text = this.$t('reLoginPlease');
          this.snackbar.visibility = true;
          setTimeout(() => {
            this.$store.dispatch('resetState');
            this.$router.push('/');
          }, 1500);
        }
      });
    },
    feedGet() {
      this.feed.isLoading = true;
      this.$store.dispatch('feedGet', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        filter: this.getComputedFilter,
        order: this.feed.order,
        offset: this.feed.offset
      })
      .then((result) => {
        if (result.success) {
          const newUsers = this.$store.getters.getUsers;
          if(newUsers.length == 0) {
            this.feed.isDataEnd = true;
          }
          else {
            this.feed.users = [...this.feed.users, ...newUsers];
            this.feed.offset += loadCount;
          }
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      });
      setTimeout(() => {
        this.feed.isLoading = false;
      }, 1000);
    },
    getMe() {
      this.$store.dispatch('userMe', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID
      })
      .then((result) => {
        if(result.success) {
          this.profileImage = (
            this.$store.getters.getProfileImageThumbnail &&
            this.$store.getters.getProfileImageThumbnail !== "null" &&
            this.$store.getters.getProfileImageThumbnail.trim() !== ""
          ) ? this.$store.getters.getProfileImageThumbnail : require('@/assets/ic_profile_placeholder.png');

          if(this.$store.getters.getNickname == "deep") {
            this.$router.push('/profile-init');
          }
          else if(this.$store.getters.getBlocked == "true") {
            this.snackbar.text = this.$t('userBlocked');
            this.snackbar.visibility = true;
            setTimeout(() => {
              this.$store.dispatch('resetState');
              this.$router.push('/');
            }, 1500);
          }
          else if(this.$store.getters.getDeleted == "true") {
            this.snackbar.text = this.$t('userDeleted');
            this.snackbar.visibility = true;
            setTimeout(() => {
              this.$store.dispatch('resetState');
              this.$router.push('/');
            }, 1500);
          }

          if(this.$store.getters.getFCMToken == "null") {
            this.openDialog('request_notification');
          }
          else {
            this.requestNotificationPermission();
          }
          
          this.chatImage = require(`@/assets/ic_chat${this.$store.getters.getNewChat === "true" ? '_unread.png' : '.png'}`);
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      })
    },
    async updateUser() {
      const existingRegistration = await navigator.serviceWorker.getRegistration("firebase-messaging-sw.js");
      const registration = existingRegistration || await navigator.serviceWorker.register("firebase-messaging-sw.js");
      await navigator.serviceWorker.ready;
      const currentToken = await getToken(this.$messaging, {
        serviceWorkerRegistration: registration,
        vapidKey: 'BAgbXzv6XuSGrcBXEr93BzMufGWGF8lxysC-s83orMvUZ8T6B9_5uWGeKydabK3BfG4dI5IbiErfeEbhIqggkgA'
      });
      await this.$store.dispatch('userUpdate', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        fcmToken: currentToken
      })
      .then((result) => {
        if (result.success) {
          console.log("user updated");
        } else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      });
    },
    setSocket() {
      this.socket = io(CHAT_SERVER, { forceNew: true });
      this.socket.on('connect', () => {
        console.log('Connected to server');
        this.socket.emit('join', `uid${this.$store.getters.getUID}`);
      });
      this.socket.on('chat', () => {
        this.getMe();
      });
    },
    reload(filter) {
      this.isDataEnd = false;
      this.feed.selectedFilter = filter;
      this.feed.offset = 0;
      this.feed.users = [];
      this.feedGet();
    },
    profileBtnCkd(ouid) {
      this.$store.commit('setOUID', ouid);
      this.$router.push('/profile');
    },
    chatBtnCkd(ouid) {
      this.$store.dispatch('chatNew', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        ouid: ouid
      })
      .then((result) => {
        if (result.success) {
          AnalyticsFunctions.logging(AnalyticsParams.CHAT_NEW);
          this.$store.commit('setRoomId', result.data);
          this.$store.commit('setOUID', ouid);
          this.$router.push('/chat');
        }
        else {
          switch (result.code) {
            case '5002':
              this.snackbar.text = this.$t('intervalTooShort');
              break;
            case '5003':
              this.snackbar.text = this.$t('pointShortage');
              break;
            default:
              this.snackbar.text = this.$t('serverError');
              break;
          }
          this.snackbar.visibility = true;
        }
      });
    },
    pwaGuideBtnCkd() {
      AnalyticsFunctions.logging(AnalyticsParams.PWA_GUIDE);
      this.$router.push('/pwa');
    },
    scrolled() {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (documentHeight - (scrollPosition + windowHeight) < 100 && !this.feed.isLoading && !this.feed.isDataEnd) {
        this.feedGet();
      }
    },
    openDialog(action) {
      this.dialog.dialogAction = action;
      this.dialog.dialogTitle = action === 'request_notification' 
        ? this.$t('requestNotification')
        : this.$t('default');
      this.dialog.dialogVisibility = true;
    },
    confrimDialog() {
      if (this.dialog.dialogAction === 'request_notification') {
        this.requestNotificationPermission()
      }
      this.dialog.dialogVisibility = false;
    },
    async requestNotificationPermission() {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          this.updateUser();
        } else {
          console.log('notification permission refused');
        }
      } catch (error) {
        console.error('notification permission error:', error);
      }
    },
  },
  created() {
    this.tokenRefresh();
    this.feedGet();
    this.getMe();
    this.setSocket();
  },
  mounted() {
    window.scrollTo(0, 0);
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
      this.isPWA = true;
      console.log("isPWA = true");
    }
    window.addEventListener('scroll', this.scrolled);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrolled);
    if (this.socket) {
      this.socket.off('chat');
      this.socket.disconnect();
    }
  },
};
</script>

<style scoped>

.main-filter-btn {
  color: white;
  background-color: transparent;
  font-weight: normal;
}

.main-filter-btn.selected-btn {
  color: var(--black-1);
  background-color: white;
  font-weight: bold;
}

.main-chat-btn {
  background-color: rgba(0, 0, 0, 0.7) !important;
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
}

.app-tool-bar {
  top:60px;
  background-color: var(--black-1) !important;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2) !important;
}

</style>