<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1024px;">

        <v-app-bar color="var(--black-1)" dark flat class="app-bar">
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="var(--red)" class="white--text font-weight-bold" @click="completeBtnCkd">{{ $t('profileEditComplete') }}</v-btn>
        </v-app-bar>

        <v-container>

          <v-row>
            <v-col class="d-flex justify-start">
              <v-btn
                  @click="profileImageSelectBtnCkd" 
                  height="100"
                  width="100"
                  icon>
                <v-img
                  :src="selectedProfileImage"
                  height="100"
                  width="100"
                  class="rounded-circle"/>
              </v-btn>
            </v-col>
            <input type="file" ref="fileInput" @change="onFileChange" style="display: none" />
          </v-row>

          <v-row>
            <v-col>
              <v-text-field 
                dark
                v-model="nickname"
                :placeholder="$t('profileEditNickname')" 
                outlined 
                hide-details 
                color="white" 
                class="ipt mt-2 profile-edit-nickname-ipt"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select 
                dark 
                :placeholder="$t('profileEditOrientation')" 
                :items="orientations" 
                v-model="selectedOrientation" 
                item-text="text"
                item-value="value"
                outlined 
                hide-details 
                color="white" 
                class="ipt mt-2 profile-edit-orientation-ipt"></v-select>
            </v-col>
          </v-row>
          
          <v-row>
            <v-col>
              <v-btn-toggle
                v-model="selectedGender"
                :mandatory="true"
                class="my-4">
                <v-btn
                  value="male"
                  :class="{'selected-btn': selectedGender === 'male'}">
                  {{ $t('profileEditGenderM') }}
                </v-btn>
                <v-btn
                  value="female"
                  :class="{'selected-btn': selectedGender === 'female'}">
                  {{ $t('profileEditGenderF') }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea 
                dark 
                :placeholder="$t('profileEditBio')"
                rows="5" 
                auto-grow 
                v-model="bio" 
                outlined 
                hide-details 
                color="white" 
                class="ipt mt-2 profile-edit-bio-ipt"></v-textarea>
            </v-col>
          </v-row>

        </v-container>

        <v-snackbar v-model="snackbar.visibility">
          <v-container class="text-center pd-0" style="padding: 0;">
            {{ snackbar.text }}
          </v-container>
          <template v-slot:actions>
            </template>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'ProfileEditView',
  data() {
    return {
      selectedProfileImage: require('@/assets/ic_profile_placeholder.png'),
      selectedProfileImageFile: null,
      nickname: '',
      selectedOrientation: '',
      orientations: [
        { text: this.$t('orientation.owner'), value: 'owner' },
        { text: this.$t('orientation.daddy'), value: 'daddy' },
        { text: this.$t('orientation.mommy'), value: 'mommy' },
        { text: this.$t('orientation.dominant'), value: 'dominant' },
        { text: this.$t('orientation.master'), value: 'master' },
        { text: this.$t('orientation.hunter'), value: 'hunter' },
        { text: this.$t('orientation.brattamer'), value: 'brattamer' },
        { text: this.$t('orientation.degrader'), value: 'degrader' },
        { text: this.$t('orientation.rigger'), value: 'rigger' },
        { text: this.$t('orientation.boss'), value: 'boss' },
        { text: this.$t('orientation.sadist'), value: 'sadist' },
        { text: this.$t('orientation.spanker'), value: 'spanker' },
        { text: this.$t('orientation.pet'), value: 'pet' },
        { text: this.$t('orientation.little'), value: 'little' },
        { text: this.$t('orientation.submissive'), value: 'submissive' },
        { text: this.$t('orientation.slave'), value: 'slave' },
        { text: this.$t('orientation.prey'), value: 'prey' },
        { text: this.$t('orientation.brat'), value: 'brat' },
        { text: this.$t('orientation.degradee'), value: 'degradee' },
        { text: this.$t('orientation.ropebunny'), value: 'ropebunny' },
        { text: this.$t('orientation.servant'), value: 'servant' },
        { text: this.$t('orientation.masochist'), value: 'masochist' },
        { text: this.$t('orientation.spankee'), value: 'spankee' },
        { text: this.$t('orientation.switch'), value: 'switch' },
      ],
      selectedGender: 'male',
      bio: '',
      snackbar: {
        text: "",
        visibility: false,
      }
    };
  },
  methods: {
    completeBtnCkd() {
      if(this.nickname == "") {
        this.snackbar.text = this.$t('nicknameEmpty');
        this.snackbar.visibility = true;
        return;
      }
      if(this.nickname.length > 10) {
        this.snackbar.text = this.$t('nicknameTooLong');
        this.snackbar.visibility = true;
        return;
      }
      if(this.selectedOrientation == "") {
        this.snackbar.text = this.$t('orientationEmpty');
        this.snackbar.visibility = true;
        return;
      }
      if(this.bio == "") {
        this.snackbar.text = this.$t('bioEmpty');
        this.snackbar.visibility = true;
        return;
      }
      if(this.bio.length > 140) {
        this.snackbar.text = this.$t('bioTooLong');
        this.snackbar.visibility = true;
        return;
      }

      if (['owner', 'daddy', 'mommy', 'dominant', 'master', 'hunter', 'brattamer', 'degrader', 'rigger', 'boss', 'sadist', 'spanker'].includes(this.selectedOrientation)) {
        this.orientation_type = 'top';
      } else if (['pet', 'little', 'submissive', 'slave', 'prey', 'brat', 'degradee', 'ropebunny', 'servant', 'masochist', 'spankee'].includes(this.selectedOrientation)) {
        this.orientation_type = 'bottom';
      } else if (this.selectedOrientation === 'switch') {
        this.orientation_type = 'switch';
      }

      this.$store.dispatch('userEdit', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        gender: this.selectedGender,
        nickname: this.nickname,
        orientation_type: this.orientation_type,
        orientation: this.selectedOrientation,
        bio: this.bio,
        profileImage: this.selectedProfileImageFile,
      })
      .then(result => {
        if (result.success) {
          this.$router.push('/main');
        } else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      })
      .catch(error => {
        console.error('User edit error:', error);
        this.snackbar.text = this.$t('serverError');
        this.snackbar.visibility = true;
      });
    },
    profileImageSelectBtnCkd() {
      this.$refs.fileInput.click();
    },
    backBtnCkd() {
      this.$router.go(-1);
    },
    onFileChange(event) {
      this.selectedProfileImageFile = event.target.files[0];
      if (this.selectedProfileImageFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.selectedProfileImage = e.target.result;
        };
        reader.readAsDataURL(this.selectedProfileImageFile);
      }
    },
  },
  created() {
    if(this.$store.getters.isLoggedIn == null) {
      this.$router.push('/');
      return;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.selectedGender = this.$store.getters.getGender;
    this.nickname = this.$store.getters.getNickname;
    this.selectedProfileImage = this.$store.getters.getProfileImage;
    this.selectedOrientation = this.$store.getters.getOrientation;
    this.bio = this.$store.getters.getBio;
  },
};
</script>

<style scoped>
.v-btn-toggle {
  width: 100%;
}
.v-btn-toggle .v-btn {
  width: 50%;
}
.selected-btn {
  background-color: var(--red) !important;
  color: white !important;
}
.v-btn-toggle .v-btn:not(.selected-btn) {
  background-color: var(--black-2) !important;
  color: white !important;
}
</style>