<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1024px;">
          
        <v-app-bar color="var(--black-1)" dark flat class="app-bar" app>
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn icon small class="mr-0" @click="profileBtnCkd">
            <v-img :src="other.profileImage" class="chat-profile-img-view rounded-circle" alt="my profile image" width="40" height="40" cover></v-img>
          </v-btn>
          <p class="ml-5 chat-name-view" style="font-weight: bold;">{{ other.nickname }}</p>
        </v-app-bar>

        <v-container class=" px-0 py-0">
          <div class="chat-message-container hide-scrollbar mt-8" ref="chatContainer">
            <div v-for="(msg, index) in chat.messages" :key="index" :class="['chat-message', { 'chat-message-other': msg.uid !== uid }]">
              <v-img v-if="msg.type === 'image'" :src="msg.contents" max-width="200" @click="showFullImage(msg.contents)"></v-img>
              <p class="text-left" v-else>{{ msg.contents }}</p>
            </div>
          </div>
        </v-container>

        <v-footer class="chat-footer" color="var(--black-1)" app>
          <v-row align="center" no-gutters>
            <v-col>
              <v-textarea
                class="chat-message-ipt"
                dark 
                v-model="chat.newMessage"
                hide-details
                placeholder="메시지 입력"
                rows="1"
                auto-grow
                flat
                solo
                background-color="var(--black-2)"
                style="min-height: 50px; max-height: 50px; overflow-y: auto; padding-top: 0 !important;"
              />
            </v-col>
            <v-col cols="auto" class="ml-2">
              <v-btn 
                min-width="40" 
                height="40" 
                icon 
                @click="chatSend" 
                :disabled="!chat.newMessage.trim()"
                class="flex-shrink-0"
              >
                <v-icon color="white">mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-footer>

        <v-snackbar v-model="snackbar.visibility">
          <v-container class="text-center pd-0" style="padding: 0;">
            {{ snackbar.text }}
          </v-container>
          <template v-slot:actions>
            </template>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {CHAT_SERVER} from '../rest/api'
import io from 'socket.io-client';

export default {
  data() {
    return {
      socket: null,
      uid: '',
      ouid: '',
      roomId: '',
      chat: {
        isInitial: true,
        isLoading: false,
        isDataEnd: false,
        scrollTop: 0,
        scrollHeight: 0,
        newMessage: '',
        messages: [],
        offset: '0',
      },
      other: {
        nickname: '',
        profileImage: require('@/assets/ic_profile_placeholder.png'),
      },
      snackbar: {
        text: "",
        visibility: false,
      }
    };
  },
  methods: {
    getOther() {
      this.$store.dispatch('userGet', {
        accessToken: this.$store.getters.getAccessToken,
        ouid: this.ouid
      })
      .then(result => {
        if(result.success) {
            this.other.nickname = this.$store.getters.getONickname;
            this.other.profileImage = this.$store.getters.getOProfileImageThumbnail || require('@/assets/ic_profile_placeholder.png');
        }
      })
    },
    chatGet() {
      this.chat.isLoading = true
      if(!this.chat.isInitial) {
        this.chat.scrollHeight = this.$refs.chatContainer.scrollHeight;
        this.chat.scrollTop = this.$refs.chatContainer.scrollTop;
      }
      this.$store.dispatch('chatGet', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.uid,
        roomId: this.roomId,
        offset: this.chat.offset
      })
      .then(result => {
          if(result.success) {
            if(result.data != "" && result.data != null) {
              this.chat.messages.unshift(...result.data.reverse());
              this.chat.offset = this.chat.messages[0].id;
            }
            else {
              this.chat.isDataEnd = true;
            }
            if(this.chat.isInitial) {
              this.chat.isInitial = false;
              this.scrollToBottom();
            }
            else {
              this.$nextTick(() => {
                const newScrollHeight = this.$refs.chatContainer.scrollHeight;
                const scrollHeightDiff = newScrollHeight - this.chat.scrollHeight;
                const newScrollTop = this.chat.scrollTop + scrollHeightDiff - 50;
                this.$refs.chatContainer.scrollTop = Math.max(newScrollTop, 0);
              });
            }
            setTimeout(() => {
              this.chat.isLoading = false;
            }, 1000);
          }
      })
      .catch(() => {
        this.chat.isLoading = false;
      });
    },
    chatSend() {
      if (this.chat.newMessage.trim()) {
        const message = {
          uid: this.uid,
          room_id: this.roomId,
          contents: this.chat.newMessage,
          type: 'text'
        };
        this.chat.messages.push(message);
        this.scrollToBottom();
        try {
          this.$store.dispatch('chatSend', {
            accessToken: this.$store.getters.getAccessToken,
            uid: this.uid,
            ouid: this.ouid,
            roomId: this.roomId,
            contents: this.chat.newMessage,
            type: 'text'
          })
          .then(result => {
            if(result.success) {
              this.socket.emit('chat', result.data);
            }
            else {
              this.snackbar.text = this.$t('serverError');
              this.snackbar.visibility = true;
            }
          });
          this.chat.newMessage = '';
        } catch (error) {
          console.error('chat send failed: ', error);
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      }
    },
    chatRead() {
      this.$store.dispatch('chatRead', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.uid,
        roomId: this.roomId
      })
      .then(result => {
        if(result.success) {
            console.log("chat read success")
        }
      })
    },
    setSocket() {
      this.socket = io(CHAT_SERVER, { forceNew: true });
      this.socket.on('connect', () => {
        console.log('Connected to server');
        this.socket.emit('join', this.roomId);
      });
      this.socket.on('chat', (data) => {
        if (data.room_id === this.roomId && data.uid !== this.uid) {
          this.chatRead()
          this.chat.messages.push(data);
          this.scrollToBottom();
        }
      });
    },
    profileBtnCkd() {
      this.$store.commit('setOUID', this.$store.getters.getOUID);
      this.$router.push('/profile');
    },
    backBtnCkd() {
      this.$router.go(-1);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },
    scrolled() {
      const container = this.$refs.chatContainer;
      if (container.scrollTop <= 10 && !this.chat.isLoading && !this.chat.isDataEnd) {
        this.chat.isLoading = true
        setTimeout(() => {
          this.chatGet();
        }, 1000);
      }
    },
  },
  created() {
    if(this.$store.getters.isLoggedIn == null) {
      this.$router.push('/');
      return;
    }
    this.uid = this.$store.getters.getUID;
    this.ouid = this.$store.getters.getOUID;
    this.roomId = this.$store.getters.getRoomId;
    this.getOther();
    this.chatRead();
    this.setSocket();
    this.chatGet();
  },
  mounted() {
    window.scrollTo(0, document.body.scrollHeight);
    this.$refs.chatContainer.addEventListener('scroll', this.scrolled);
  },
  beforeDestroy() {
    this.$refs.chatContainer.removeEventListener('scroll', this.scrolled);
    if (this.socket) {
      this.socket.off('chat');
      this.socket.disconnect();
    }
  }
};
</script>

<style scoped>

.v-container, .v-main, .v-app {
  overflow: hidden; /* 상위 요소에서 스크롤이 발생하지 않도록 */
}

.chat-footer {
  max-width: 1024px !important;
  margin: 0 auto !important;
  padding:10px !important;
  background-color: var(--black-2) !important;
}

.v-application {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.v-main {
  flex: 1;
}

.chat-message-ipt {
  padding:10px !important;
  padding-bottom:15px !important;
  overflow:hidden !important;
}

textarea {
  resize:none;
}

.chat-message-parent-container {
  height: calc(100vh - 190px);
  
}

.chat-message-container {
  height: calc(100vh - 190px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.chat-message {
  max-width: 256px;
  overflow-wrap: break-word;
  background-color: var(--black-2);
  color: white;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  align-self: flex-end;
  display: block;
}

.chat-message-other {
  max-width: 256px;
  overflow-wrap: break-word;
  background-color: var(--black-3);
  color: white;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  align-self: flex-start;
  display: block;
}

</style>