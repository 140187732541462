<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
  }),
  /* internet offline check */
  mounted() {
    window.scrollTo(0, 0);
    window.addEventListener('online', this.checkConnection);
    window.addEventListener('offline', this.checkConnection);
  },
  methods: {
    checkConnection() {
      if (navigator.onLine) {
        if (this.$route.path === '/no-internet') {
          this.$router.push('/');
        }
      } else {
        this.$router.push('/no-internet');
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('online', this.checkConnection);
    window.removeEventListener('offline', this.checkConnection);
  }
}

</script>

<style>

:root {
  --black-1: #1C1D1D;
  --black-2: #323333;
  --black-3: #474747;
  --black-4: #515151;
  --red: #EC6B6B;
}

html {
  background-color: var(--black-1);
}

#app {
  -webkit-overflow-scrolling: touch;
  background-color: var(--black-1);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

p {
  margin: 0 !important;
}

.full-size {
  width: 100%;
  height: 100%;
}

.app-bar {
  max-width: 1024px !important;
  margin: 0 auto !important;
  height: 80px !important;
  padding: 10px;
}

.checkbox {
  margin: 0px !important;
}

.ipt {
  background-color: var(--black-2) !important;
}

.btn-red {
  background-color: var(--red) !important;
}

.v-snack__wrapper {
  width: calc(100% - 48px) !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 24px !important;
}

.highlight {
  background-color: var(--black-1);
  color: white;
  padding: 2px 4px;
  border-radius: 4px;
}

</style>
