<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1024px;">

        <v-app-bar color="var(--black-1)" dark flat class="app-bar" app>
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <p>{{$t('profileTitle')}}</p>
        </v-app-bar>

        <v-container>
          <v-row class="mt-1" justify="center">
            <v-col cols="auto" class="pa-0">
              <v-img
                class="rounded-circle"
                :src="profileImage"
                width="100"
                height="100"
                cover/>
            </v-col>
          </v-row>
          
          <v-row>
            <v-col>
              <p class="profile-nickname-view mt-2" style="background-color: var(--black-1);color: white;font-weight: bold;">{{nickname}}</p>
            </v-col>
          </v-row>
          
          <v-row>
            <v-col>
              <p class="grey--text profile-orientation-view" style="background-color: var(--black-1);color: white;">{{ getComputedOrientation(orientation) + ' · ' + getComputedGender(gender) }}</p>
            </v-col>
          </v-row>
          
          <v-row>
            <v-col>
              <p class="grey--text profile-bio-view" style="background-color: var(--black-1);color: white;">{{bio}}</p>
            </v-col>
          </v-row>
        </v-container>

        <v-btn
          block 
          class="white--text btn-red mt-3" 
          @click.stop="chatBtnCkd()">
          <b>채팅</b>
        </v-btn>

        <v-snackbar v-model="snackbar.visibility">
          <v-container class="text-center pd-0" style="padding: 0;">
            {{ snackbar.text }}
          </v-container>
          <template v-slot:actions>
            </template>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { AnalyticsFunctions } from '../rest/analytics/functions.js';
import { AnalyticsParams } from '../rest/analytics/params.js';

export default {
  name: 'ProfileView',
  data() {
    return {
      ouid: null,
      profileImage: require('@/assets/ic_profile_placeholder.png'),
      gender: null,
      nickname: null,
      orientation: null,
      bio: null,
      snackbar: {
        text: "",
        visibility: false,
      }
    }
  },
  computed: {
    getComputedOrientation() {
      return (orientation) => {
        const translated = this.$t(`orientation.${orientation}`);
        return typeof translated === 'string' ? translated : orientation;
      }
    },
    getComputedGender() {
      return (gender) => {
        const translated = this.$t(`gender.${gender}`);
        return typeof translated === 'string' ? translated : gender;
      }
    }
  },
  methods: {
    getOther() {
      this.$store.dispatch('userGet', {
        accessToken: this.$store.getters.getAccessToken,
        ouid: this.ouid
      })
      .then((result) => {
        if(result.success) {
          var profileImage = this.$store.getters.getOProfileImageThumbnail
          if(profileImage && profileImage !== "null" && profileImage !== "") {
            this.profileImage = profileImage
          }
          this.gender = this.$store.getters.getOGender;
          this.nickname = this.$store.getters.getONickname;
          this.orientation = this.$store.getters.getOOrientation;
          this.bio = this.$store.getters.getOBio;
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      });
    },
    chatBtnCkd() {
      this.$store.dispatch('chatNew', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        ouid: this.ouid
      })
      .then((result) => {
        if (result.success) {
          AnalyticsFunctions.logging(AnalyticsParams.CHAT_NEW);
          this.$store.commit('setRoomId', result.data);
          this.$store.commit('setOUID', this.ouid);
          this.$router.push('/chat');
        }
        else {
          switch (result.code) {
            case '5002':
              this.snackbar.text = this.$t('intervalTooShort');
              break;
            case '5003':
              this.snackbar.text = this.$t('pointShortage');
              break;
            default:
              this.snackbar.text = this.$t('serverError');
              break;
          }
          this.snackbar.visibility = true;
        }
      });
    },
    backBtnCkd() {
      this.$router.go(-1);
    }
  },
  created() {
    if(this.$store.getters.isLoggedIn == null) {
      this.$router.push('/');
      return;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.ouid = this.$store.getters.getOUID;
    this.getOther()
  },
};
</script>

<style scoped>

.profile-bio-view {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

</style>