import axios from 'axios';

const TOKEN_REFRESH = "https://deep.aeida.io/api/token/refresh.php"

const USER_LOGIN = "https://deep.aeida.io/api/user/login.php";
const USER_REGISTER = "https://deep.aeida.io/api/user/register.php";
const USER_INIT = "https://deep.aeida.io/api/user/init.php";
const USER_EDIT = "https://deep.aeida.io/api/user/edit.php";
const USER_ME = "https://deep.aeida.io/api/user/me.php";
const USER_GET = "https://deep.aeida.io/api/user/get.php";
const USER_UPDATE = "https://deep.aeida.io/api/user/update.php";
const USER_DELETE = "https://deep.aeida.io/api/user/delete.php";

const FEED_GET = "https://deep.aeida.io/api/feed/get.php"

export const CHAT_SERVER = "https://deep.aeida.io:3000";
const CHAT_LIST = "https://deep.aeida.io/api/chat/list.php";
const CHAT_GET = "https://deep.aeida.io/api/chat/get.php";
const CHAT_NEW = "https://deep.aeida.io/api/chat/new.php";
const CHAT_SEND = "https://deep.aeida.io/api/chat/send.php";
const CHAT_READ = "https://deep.aeida.io/api/chat/read.php";

export default {
  tokenRefresh(accessToken, uid) {
    const formData = new FormData();
    formData.append('uid', uid);
    return axios.post(TOKEN_REFRESH, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userLogin(user_name, pw) {
    const formData = new FormData();
    formData.append('user_name', user_name);
    formData.append('pw', pw);
    return axios.post(USER_LOGIN, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  userRegister(os, user_name, pw) {
    const formData = new FormData();
    formData.append('os', os);
    formData.append('user_name', user_name);
    formData.append('pw', pw);
    return axios.post(USER_REGISTER, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  userInit(accessToken, uid, gender, nickname, orientation_type, orientation, bio, profileImage) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('gender', gender);
    formData.append('nickname', nickname);
    formData.append('orientation_type', orientation_type);
    formData.append('orientation', orientation);
    formData.append('bio', bio);
    formData.append('profile_image', profileImage);
    return axios.post(USER_INIT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userEdit(accessToken, uid, gender, nickname, orientation_type, orientation, bio, profileImage) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('gender', gender);
    formData.append('nickname', nickname);
    formData.append('orientation_type', orientation_type);
    formData.append('orientation', orientation);
    formData.append('bio', bio);
    formData.append('profile_image', profileImage);
    return axios.post(USER_EDIT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userMe(accessToken, uid) {
    const formData = new FormData();
    formData.append('uid', uid);
    return axios.post(USER_ME, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userGet(accessToken, ouid) {
    const formData = new FormData();
    formData.append('ouid', ouid);
    return axios.post(USER_GET, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userUpdate(accessToken, uid, fcmToken) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('fcm_token', fcmToken);
    return axios.post(USER_UPDATE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userDelete(accessToken, uid) {
    const formData = new FormData();
    formData.append('uid', uid);
    return axios.post(USER_DELETE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  feedGet(accessToken, uid, filter, order, offset) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('filter', filter);
    formData.append('order', order);
    formData.append('offset', offset);
    return axios.post(FEED_GET, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  chatList(accessToken, uid, offset) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('offset', offset);
    return axios.post(CHAT_LIST, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  chatGet(accessToken, uid, roomId, offset) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('room_id', roomId);
    formData.append('offset', offset);
    return axios.post(CHAT_GET, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  chatNew(accessToken, uid, ouid) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('ouid', ouid);
    return axios.post(CHAT_NEW, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  chatSend(accessToken, uid, ouid, roomId, contents, type) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('ouid', ouid);
    formData.append('room_id', roomId);
    formData.append('contents', contents);
    formData.append('type', type);
    return axios.post(CHAT_SEND, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  chatRead(accessToken, uid, roomId) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('room_id', roomId);
    return axios.post(CHAT_READ, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
};