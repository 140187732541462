import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import user from './user';
import other from './other';
import feed from './feed';
import chat from './chat';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: user,
    other: other,
    feed: feed,
    chat: chat
  },
  plugins: [(new VuexPersist({
    storage: window.localStorage,
    reducer: (state) => ({ user: state.user, other: state.other, chat: state.chat })
  })).plugin]
});