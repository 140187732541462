<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1024px;">

        <v-app-bar color="var(--black-1)" dark flat class="app-bar" app>
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <p>{{ $t('settingsTitle') }}</p>
        </v-app-bar>
        
        <v-container>
          <v-card class="settings-container text-left" @click="$router.push('/profile-edit')">
            <v-card-text style="color:white">
              {{ $t('settingsProfileEdit') }}
            </v-card-text>
          </v-card>

          <v-card class="settings-container text-left mt-3" @click="openDialog('logout')">
            <v-card-text style="color:white">
              {{ $t('settingsAccountLogout') }}
            </v-card-text>
          </v-card>

          <v-card class="settings-container text-left mt-3" @click="openDialog('delete')">
            <v-card-text style="color:gray">
              {{ $t('settingsAccountDelete') }}
            </v-card-text>
          </v-card>
        </v-container>

        <v-dialog v-model="dialog.dialogVisibility" max-width="400px">
          <v-card style="background-color: var(--black-2)">
            <v-card-title style="color: white; font-size: 16px;">
              {{ dialog.dialogTitle }}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="var(--black-3)" style="color: white;" @click="confirmDialog">
                {{ $t('alertOK') }}
              </v-btn>
              <v-btn color="var(--red)" dark @click="dialog.dialogVisibility = false">
                {{ $t('alertBack') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { AnalyticsFunctions } from '../rest/analytics/functions.js';
import { AnalyticsParams } from '../rest/analytics/params.js';

export default {
  name: 'SettingsView',
  data() {
    return {
      dialog: {
        dialogVisibility: false,
        dialogTitle: '',
        dialogAction: '',
      }
    };
  },
  methods: {
    logout() {
      this.$store.dispatch('resetState');
      this.$router.push('/');
    },
    deleteAccount() {
      this.$store.dispatch('userDelete', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID
      })
      .then(result => {
        if (result.success) {
          AnalyticsFunctions.logging(AnalyticsParams.DELETE);
          return this.$store.dispatch('resetState');
        } else {
          this.snackbarText = this.$t('serverError');
          this.snackbar = true;
          throw new Error('User deletion failed');
        }
      })
      .then(() => {
        this.$router.push('/');
      })
      .catch(error => {
        console.error('User deletion error:', error);
        if (!this.snackbar) {
          this.snackbarText = this.$t('serverError');
          this.snackbar = true;
        }
      });
    },
    openDialog(action) {
      this.dialog.dialogAction = action;
      this.dialog.dialogTitle = action === 'logout' 
        ? this.$t('confirmLogout')
        : this.$t('confirmAccountDelete');
      this.dialog.dialogVisibility = true;
    },
    confirmDialog() {
      if (this.dialog.dialogAction === 'logout') {
        this.logout();
      } else if (this.dialog.dialogAction === 'delete') {
        this.deleteAccount();
      }
      this.dialog.dialogVisibility = false;
    },
    backBtnCkd() {
      this.$router.go(-1);
    }
  },
  created() {
    if(this.$store.getters.isLoggedIn == null) {
      this.$router.push('/');
      return;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.settings-container {
  background-color: var(--black-2);
}
</style>