<template>
  <v-app style="height: 100vh; overflow: auto;">
    <v-main class="d-flex align-center justify-center full-size">
      <v-container style="max-width: 1024px;">
        <v-row justify="center">

          <v-col cols="12" class="text-center mb-10">
            <v-img :src="require('@/assets/ic_logo.png')" alt="Logo" height="80" width="80" max-width="150" class="mx-auto mb-2" contain></v-img>
            <h2 class="white--text font-weight-bold">
              {{$t('appSubTitle')}}
            </h2>
          </v-col>

          <v-col cols="12" class="text-center">
            <v-text-field
              v-model="email"
              dark 
              :placeholder="$t('loginEmail')" 
              outlined 
              hide-details 
              color="white" 
              class="ipt mb-4 login-email-ipt"/>
              <v-text-field
              v-model="pw"
              dark 
              :placeholder="$t('loginPassword')" 
              outlined 
              hide-details 
              type="password" 
              color="white" 
              class="ipt mb-4 login-pw-ipt"/>
            <v-btn
              class="btn-red white--text font-weight-bold" 
              block 
              rounded 
              large
              v-on:click="completeBtnCkd">
              {{$t('loginTitle')}}
            </v-btn>
          </v-col>

          <v-col cols="12" class="text-center">
            <v-btn to="/register" class="white--text" text>
              {{$t('loginRegister')}}
            </v-btn>
          </v-col>

          <v-snackbar v-model="snackbar.visibility">
            <v-container class="text-center pd-0" style="padding: 0;">
              {{ snackbar.text }}
            </v-container>
            <template v-slot:actions>
              </template>
          </v-snackbar>

        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import functions from '../rest/functions';
import { AnalyticsFunctions } from '../rest/analytics/functions.js';
import { AnalyticsParams } from '../rest/analytics/params.js';

export default {
  name: 'LoginView',
  data() {
    return {
      email: "",
      pw: "",
      snackbar: {
        text: "",
        visibility: false,
      }
    }
  },
  methods: {
    async completeBtnCkd() {
      window.scrollTo(0, 0);
      if (this.email === "") {
        this.snackbar.text = this.$t('emailEmpty');
        this.snackbar.visibility = true;
        return;
      }
      if (!functions.isValidEmail(this.email)) {
        this.snackbar.text = this.$t('emailInvalid');
        this.snackbar.visibility = true;
        return;
      }
      if (this.pw === "") {
        this.snackbar.text = this.$t('pwEmpty');
        this.snackbar.visibility = true;
        return;
      }
      this.$store.dispatch('userLogin', {
        user_name: this.email,
        password: this.pw
      })
      .then(result => {
        if (result.success) {
          AnalyticsFunctions.logging(AnalyticsParams.LOGIN);
          this.$router.push('/main');
        } else {
          switch (result.code) {
            case '401':
              this.snackbar.text = this.$t('userInvalid');
              break;
            case '404':
              this.snackbar.text = this.$t('userNotExist');
              break;
            default:
              this.snackbar.text = this.$t('serverError');
              break;
          }
          this.snackbar.visibility = true;
        }
      })
      .catch(error => {
        console.error('Login error:', error);
        this.snackbar.text = this.$t('serverError');
        this.snackbar.visibility = true;
      });
    },
    mounted() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style scoped>

</style>