export default {
  getOS() {
    var userAgent = window.navigator.userAgent.toLowerCase();
    var platform = window.navigator.platform.toLowerCase();
    if (platform.includes('mac')) {
        return 'mac';
    } else if (platform.includes('win')) {
        return 'windows';
    } else if (userAgent.includes('android')) {
        return 'android';
    } else if (/iphone|ipad|ipod/.test(userAgent)) {
        return 'ios';
    } else {
        return 'else';
    }
  },
  isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  },
};