import Vue from 'vue';
import App from './App.vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

import router from './router';
import store from './store';
import VueI18n from 'vue-i18n';

import en from './locale/en';
import ko from './locale/ko';

import fcmIcon from '@/assets/ic_fcm.png'
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { AnalyticsFunctions } from './rest/analytics/functions.js';

/* fcm */
const firebaseConfig = {
  apiKey: "AIzaSyB1XqYoV6uJ3LMjtQ6jyqB1130BUn-oKgc",
  authDomain: "deep-0416.firebaseapp.com",
  projectId: "deep-0416",
  storageBucket: "deep-0416.appspot.com",
  messagingSenderId: "308974040497",
  appId: "1:308974040497:web:2b551d9db336f4518bf990",
  measurementId: "G-Q9EX692TR0"
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
Vue.prototype.$messaging = messaging;
onMessage(messaging, (payload) => {
  if (document.visibilityState === 'visible') {
    if (Notification.permission === 'granted') {
      const notificationTitle = payload.data.title;
      const notificationOptions = {
        body: payload.data.body,
        icon: fcmIcon
      };
      new Notification(notificationTitle, notificationOptions);
    } else {
      console.log('Notification permission not granted');
    }
  }
});

/* analytics */
AnalyticsFunctions.initAnalytics(firebaseApp);

/* vue */
Vue.config.productionTip = false;
Vue.use(Vuetify);
Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'ko',
  messages: {
    en: en,
    ko: ko
  },
});

const vuetify = new Vuetify({
});

new Vue({
  store,
  vuetify: vuetify,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');